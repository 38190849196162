<template>
  <v-col cols="12">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel readonly>
        <v-btn
          style="position: absolute; top: -15px; right: 55px; z-index: 1"
          color="success"
          title="Nueva cuenta"
          small
          fab
          @click="open_dialog(null)"
        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
        <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
          <div>
            <v-icon class="mb-1" left>fas fa-filter</v-icon>
            Filtro cuentas
          </div>
          <template v-slot:actions>
            <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
              <v-icon>fas fa-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-1">
          <v-form @submit.prevent="buscar()">
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" sm="6" md="3" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              <v-col cols="12" sm="6" md="2" class="py-1">
                Número de cuenta
                <v-text-field
                  v-model.trim="filtro.numero"
                  tabindex="1"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="py-1">
                CBU
                <v-text-field
                  v-model.trim="filtro.cbu"
                  tabindex="1"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                <v-switch
                  v-model="filtro.inhabilitada"
                  label="Inhabilitada"
                  class="mb-md-2"
                  tabindex="1"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  dense
                ></v-switch>
              </v-col>
              <BtnFiltro
                :loading="load"
                @clear="limpiar()"
              />
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    
    <v-data-table
      class="cebra elevation-2 mt-2"
      :headers="headers"
      :items="cuentas"
      :loading="load"
      :search="search"
      sort-by="nombre"
      dense
    >
      <template v-slot:top>
        <v-row class="d-flex justify-end pa-2" no-gutters>
          <v-col cols="6" sm="3" md="2">
            <SearchDataTable
              v-model="search"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="!item.inhabilitada"
          color="primary"
          title="Editar"
          class="mr-2"
          small
          @click="open_dialog(item)"
        >
          fas fa-pen
        </v-icon>
        <BtnConfirmar
          :icono="item.inhabilitada ? 'fas fa-check' : 'fas fa-ban'"
          :color="item.inhabilitada ? 'success' : 'error'"
          :title="item.inhabilitada ? 'Habilitar' : 'Inhabilitar'"
          :texto="item.inhabilitada
            ? `¿Desea habilitar la cuenta <strong>${item.numero}</strong> del banco <strong>${item.banco_nombre}</strong>?`
            : `¿Desea inhabilitar la cuenta <strong>${item.numero}</strong> del banco <strong>${item.banco_nombre}</strong>?`"
          :icon_button="true"
          :small="true"
          @action="item.inhabilitada ? habilitar(item) : inhabilitar(item)"
        />
      </template>
      <template v-slot:no-data>
        <v-alert
          class="mx-auto mt-4"
          max-width="400"
          type="warning"
          border="left"
          dense
          text
        >
          No hay datos para los filtros seleccionados
        </v-alert>
      </template>
    </v-data-table>
    <Cuenta
      v-model="dialog"
      :p_nueva="nueva"
      :p_cuenta="cuenta"
      @editar="editar"
    />
  </v-col>
</template>

<script>
/**
 *  Formulario de cuentas bancarias
 * 
 *  Notas: si se utiliza desde la vista Bancos, se deben enviar los dos props, uno indicando el id del banco
 *         para traer todas las cuentas de dicho banco, y el otro es el array de los bancos seleccionados, este
 *         ultimo se utiliza para limpiarlo en el caso que haga click en el boton buscar del filtro y asi
 *         deseleccionar los bancos seleccionados en la vista padre y no generar confusion.
 */
import { mapState } from 'vuex'
import BtnFiltro from '../../util/BtnFiltro'
import BtnConfirmar from '../../util/BtnConfirmar'
import SearchDataTable from '../../util/SearchDataTable'
import Cuenta from './Cuenta'

export default {
  data () {
    return {
      panel: 1,
      search: '',
      load: false,
      nueva: false,
      dialog: false,
      tipos: [],
      cuentas: [],
      headers: [
        { text: 'Número', value: 'numero' },
        { text: 'CBU', value: 'cbu' },
        { text: 'Tipo de cuenta', value: 'tipo_nombre' },
        { text: 'Empresa', value: 'empresa_nombre' },
        { text: 'Banco', value: 'banco_nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      cuenta: {},
      filtro: {
        empresa: null,
        numero: null,
        cbu: null,
        inhabilitada: 0
      }
    }
  },
  props: {
    p_banco: {
      type: Number,
      default: null
    },
    p_selected: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapState(['empresas']),
    selected: {
      get () {
        return this.p_selected
      },
      set (value) {
        this.$emit('update:p_selected', value)
      }
    }
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    Cuenta
  },
  watch: {
    async p_banco (val) {
      this.cuentas = []
      if (val) {
        this.$store.state.loading = true
        this.search = ''
        await this.$store.dispatch('bancos/get_cuentas', {
          banco: this.p_banco,
          inhabilitada: 0
        })
          .then((res) => {
            this.cuentas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    }
  },
  methods: {
    async buscar () {
      this.load = true
      this.search = ''
      this.cuentas = []
      this.selected = []
      await this.$store.dispatch('bancos/get_cuentas', this.filtro)
        .then((res) => {
          this.cuentas = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('bancos/habilitar_cuenta', item.codigo)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // "elimina" la cuenta de la lista
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('bancos/inhabilitar_cuenta', item.codigo)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // "elimina" la cuenta de la lista
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    editar (item) {
      // actualiza la cuenta en la lista
      let cuenta = this.cuentas.find(cu => cu.codigo == item.codigo)
      Object.assign(cuenta, item)
      // si la inhabilito la quita de la lista
      if (cuenta.inhabilitada == 1) {
        this.quitar(cuenta)
      }
    },
    quitar (item) {
      const index = this.cuentas.indexOf(item)
      this.cuentas.splice(index, 1)
    },
    open_dialog (item) {
      if (item) {
        this.nueva = false
        this.cuenta = JSON.parse(JSON.stringify(item))
      } else {
        this.nueva = true
        this.cuenta = {}
      }
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        empresa: null,
        numero: null,
        cbu: null,
        inhabilitada: 0
      }
    }
  }
}
</script>