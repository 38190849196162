<template>
  <v-dialog
    v-model="dialog"
    max-width="350"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nuevo ? 'Nuevo banco' : 'Editar banco' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8 pb-2">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="nombre"
                label="Nombre"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          class="mr-2"
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear un banco
 * 
 *  Notas: este componente emite acciones (@nuevo, @editar) al padre caundo se crea o edita un banco (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import BtnConfirmar from '../../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      nombre: null,
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  props: {
    value: Boolean,
    p_nuevo: Boolean, // para editar un banco enviar en false, para un banco nuevo en true
    p_nombre: String, // en caso de ser nuevo enviar null
    p_codigo: Number, // codigo del banco a editar, en caso de ser nuevo enviar null
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        this.nombre = this.p_nombre
      } else {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nuevo) {
          await this.$store.dispatch('bancos/nuevo_banco', this.nombre)
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              // emite la accion 'nuevo' definifa en el padre
              this.$emit('nuevo', res.banco)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // solo guarda si realmente se modifico el nombre
          if (this.nombre != this.p_nombre) {
            let data = {
              codigo: this.p_codigo,
              nombre: this.nombre.toUpperCase()
            }
            await this.$store.dispatch('bancos/editar_banco', data)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                // emite la accion 'editar' definifa en el padre
                this.$emit('editar', data)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: `No se realizaron cambios en el ${this.nombre}: el nuevo nombre es igual al anterior`,
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    }
  }
}
</script>