<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nueva ? 'Nueva cuenta' : 'Editar cuenta' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8 pb-2">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="cuenta.numero"
                label="Número"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="cuenta.cbu"
                label="CBU"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="cuenta.tipo_codigo"
                label="Tipo"
                item-text="nombre"
                item-value="codigo"
                :items="tipos_cuenta"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="cuenta.empresa_codigo"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="cuenta.banco_codigo"
                label="Banco"
                item-text="nombre"
                item-value="codigo"
                :items="bancos"
                :rules="[rules.required]"
                :loading="load_banco"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-switch
                v-if="!p_nueva"
                v-model="cuenta.inhabilitada"
                label="Inhabilitada"
                class="d-flex align-top mt-0"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          class="mr-2"
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una cuenta bancaria
 * 
 *  Notas: este componente emite acciones (@nueva, @editar) al padre caundo se crea o edita una cuenta (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import { mapState } from 'vuex'
import { get_nombre_obj_arr } from '../../../util/utils'
import BtnConfirmar from '../../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      load_banco: false,
      cuenta: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('bancos/get_tipos_cuenta')
    await this.$store.dispatch('bancos/get_bancos_habilitados')
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_nueva: Boolean, // para editar una cuenta enviar en false, para una cuenta nueva en true
    p_cuenta: Object  // en caso de ser nueva enviar el objeto vacio {}
  },
  computed: {
    ...mapState(['empresas']),
    ...mapState('bancos', ['bancos', 'tipos_cuenta']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_nueva) {
          // si es nueva setea los campos en null
          this.cuenta = {
            numero: null,
            cbu: null,
            tipo_codigo: null,
            empresa_codigo: null,
            banco_codigo: null
          }
        } else {
          // crea una copia del prop p_cuenta para editarlo
          this.cuenta = JSON.parse(JSON.stringify(this.p_cuenta))
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nueva) {
          await this.$store.dispatch('bancos/nueva_cuenta', this.cuenta)
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              // emite la accion 'nueva' definifa en el padre
              this.$emit('nueva', res.cuenta)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          const banco = get_nombre_obj_arr(this.bancos, 'codigo', this.cuenta.banco_codigo)
          // solo edita si realmente se modifico la cuenta
          if (JSON.stringify(this.cuenta) != JSON.stringify(this.p_cuenta)) {
            await this.$store.dispatch('bancos/editar_cuenta', this.cuenta)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                // obtiene el nombre del tipo, la empresa y el banco
                this.cuenta.tipo_nombre = get_nombre_obj_arr(this.tipos_cuenta, 'codigo', this.cuenta.tipo_codigo)
                this.cuenta.empresa_nombre = get_nombre_obj_arr(this.empresas, 'id', this.cuenta.empresa_codigo, 'nombre_corto')
                this.cuenta.banco_nombre = banco
                // emite la accion 'editar' definifa en el padre
                this.$emit('editar', this.cuenta)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: `No se realizaron cambios en la cuenta ${this.cuenta.numero} del ${banco}: los nuevos valores son iguales a los anteriores`,
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    }
  }
}
</script>